<template>
    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="btn-icon rounded-circle mb-2"
        @click="goBack">
        <feather-icon icon="ArrowLeftIcon" />
    </b-button>
</template>
<script>
import { BButton } from 'bootstrap-vue';
export default {

    components: {
        BButton
    },
    methods: {
        goBack(){
            this.$router.go(-1)
        }
    }
}
</script>