<template>
    <div>
        <b-modal id="modal-danger" ok-only ok-variant="danger" ok-title="Remove" modal-class="modal-danger" centered
            title="Are you sure to remove the user?" v-model="removeUser" @ok="removeUserFromTeam">
        </b-modal>
        <BackButton />
        <div class="card p-2">
            <h2 class="text-primary">{{ teamdetails.name }}</h2>
            <p>{{ teamdetails.description }}</p>
        </div>
        <div class="card p-2">
            <h5>Team Members</h5>
            <table role="table" class="table table-hover">
                <thead role="rowgroup">
                    <tr role="row">
                        <th role="columnheader" scope="col">#</th>
                        <th role="columnheader" scope="col">Name</th>
                        <th role="columnheader" scope="col">Action</th>
                    </tr>
                </thead>
                <tbody role="rowgroup">
                    <!-- {{ filteredUsers }} -->
                    <template v-if="filteredUsers.length != 0">
                        <tr v-for="(userr, index) in filteredUsers">
                            <td>{{ index + 1 }}</td>
                            <td>{{ userr.firstname }} {{ userr.lastname }}</td>
                            <td><button class="btn btn-danger btn-sm" @click="removeUserModel(userr._id)">-
                                    Remove</button></td>
                        </tr>
                    </template>
                    <template v-else>
                        <empty-table-section title="Users Not Found">
                        <template #content>
                            <p class="font-weight-bold text-center">
                            It seems like there are no Members at the moment.
                            </p>
                        </template>
                        </empty-table-section>
                    </template>

                </tbody>
            </table>
        </div>


    </div>

</template>
<script>
import ResponseMixins from "../../mixins/ResponseMixins";
import TeamsMixins from "../../mixins/TeamsMixins";
import BackButton from "../../components/BackButton.vue";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
export default {
    components: {
        BackButton,
        EmptyTableSection
    },
    data() {
        return {
            teamdetails: [],
            teamid: this.$route.params.id,
            filteredUsers: [],
            userids: [],
            userid: '',
            removeUser: false
        }
    },
    mounted() {
        this.getTeamDetailsandSetData();

    },
    mixins: [ResponseMixins, TeamsMixins],
    methods: {

        getTeamDetailsandSetData() {
            this.getTeamDetails(this.teamid).then((res) => {
                this.teamdetails = res.data.data
                this.userids = res.data.data.user_ids;
                this.filterUsersById(this.userids)
            })
                .catch((err) => {
                    console.log(err)
                })
        },
        filterUsersById(userIds) {
            this.filteredUsers = this.teamdetails.users.filter(user => userIds.includes(user._id));
            this.updateUsersOptions();
        },
        removeUserModel(id) {
            this.userid = id;
            this.removeUser = true;
        },
        removeUserFromTeam() {
            this.removeUserFromTeams(this.teamid, this.userid).then((res) => {
                this.handleResponse(res);
                this.removeUser = false;
                this.getTeamDetailsandSetData()
            })
                .catch((err) => {
                    this.handleError(err);
                })
        }
    }
}
</script>